<template>
     <div class="bg-gray-700 tabela">
         <div class="relative flex flex-col h-full min-w-0 break-words w-full mb-6 shadow-lg bg-gray-800 text-white">
            <div class="block w-full bg-gray-700 teste">
               <table class="items-center w-full h-full">
                  <thead>
                     <tr>
                        <th class="px-6 align-middle  py-3 text-xl uppercase border-b-2 border-t-2 whitespace-no-wrap  text-left bg-gray-800 text-white border-gray-600">NOME</th>
                        <th class="px-6 align-middle  py-3 text-xl uppercase border-b-2 border-t-2 whitespace-no-wrap  text-left bg-gray-800 text-gray border-gray-600">LOCALIDADE</th>
                        <th class="px-6 align-middle  py-3 text-xl uppercase border-b-2 border-t-2 whitespace-no-wrap  text-left bg-gray-800 text-gray border-gray-600">STATUS</th>
                     </tr>
                  </thead>
                  <Table v-for="(post, index) in posts" :key="index" :post="post" />
               </table>
            </div>
         </div>
      </div>
</template>
<script>
import Table from './Table';
export default {
    props: {
     posts: {
      type: Array,
      required: true,
        },
     },
    components: {
        Table
    },
    }
</script>
<style>
.tabela {
    position: relative;
    top: 0px;
    left: 0px;
    height:100vh;
    right: 0px;
    margin-top: 0px;
},
.teste{
    overflow: hidden;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden;

}

</style>
