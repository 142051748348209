<template>
    <tr>
      <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xl whitespace-no-wrap p-4 text-left flex items-center">
          <span class="font-bold NaN">{{post.nome}}</span>
      </th>
      <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xl whitespace-no-wrap p-4">{{post.endereco}}</td>
      <td v-if="post.status==='ENTREGUE'" class="border-t-0 px-6 align-middle border opacity-90 bg-green-900 border-l-0 border-r-0 text-xl whitespace-no-wrap p-4">
          {{post.status}}
      </td>
       <td v-if="post.status==='EM TRÂNSITO'" class="border-t-0 px-6 align-middle opacity-90 bg-gray-900 border-l-0 border-r-0 text-xl whitespace-no-wrap p-4">
          {{post.status}}
      </td>
       <td v-if="post.status==='EM SEPARAÇÃO'" class="border-t-0 px-6 align-middle  opacity-90 bg-pink-900 border-l-0 border-r-0 text-xl whitespace-no-wrap p-4">
          {{post.status}}
      </td>
        <td v-if="post.status==='EM PREPARAÇÃO'" class="border-t-0 px-6 align-middle  opacity-90 bg-purple-900 border-l-0 border-r-0 text-xl whitespace-no-wrap p-4">
          {{post.status}}
      </td>
    </tr>   					
</template>
<script>

export default {
props: {
    post: {
      type: Object,
      required: true,
    },
  }}
</script>

