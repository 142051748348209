<template>
    <section>
            <nav class="relative px-8 py-8 flex justify-between items-center bg-gray-900">
               <img class="h-12" src="../assets/logo.svg" alt="" width="380" height="320"> 
               <p href="#" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xl font-medium text-white bg-gray-900 hover:bg-gray-900">
                  {{time}}
                </p> 
               <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0 bg-gray-900 rounded-md">
                
                <p href="#" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-xl font-medium text-white bg-gray-900 hover:bg-gray-900">
                 TEMPERATURA {{clima.temp}}º
                </p>
                <img class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-gray-900" src="../assets/tech2.png" width="225" height="220">              
               </div> 
               <div class="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:flex lg:items-center lg:w-auto lg:space-x-6">               
               </div>
            </nav>
            <Slider/>
            <div class="relative flex flex-col h-full min-w-0 break-words w-full shadow-lg bg-gray-700 text-white">
               <div class="flex flex-wrap items-center">
                  <div class="relative w-full h-full px-4 max-w-full flex-grow flex-1">
                     <h3 class="font-bold NaN text-xs text-white">ENTREGAS - {{date}}</h3>
                  </div>
                   
               </div>
            </div>
      </section>
      
</template>
<script>
import Slider from './slider.vue'
import axios from "axios";

export default {

data() {
    return {
      interval: null,
      intervaloClima:null,
      time: null,
      date:null,
      clima:[]
    }
  },
  mounted(){
    this.PegarDados();
  },
methods:{
  async PegarDados(){
      const resposta = await axios.get('https://api.hgbrasil.com/weather?format=json-cors&key=a2d4f5fd&city_name=Ibicoara,BA');
      if (resposta.status == 200){
        this.clima = resposta.data.results; }
      else{
        console.log("error")}
    }
  },
  components:{
     Slider,
  },

  beforeUnmount() {
    // prevent memory leak
    clearInterval(this.interval)
    clearInterval(this.intervaloClima)
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }).format()
      this.date = Intl.DateTimeFormat(navigator.language, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }).format()
    }, 1000);
  
   this.intervaloClima = setInterval(() => {
   this.PegarDados()}, 3600000)
  }
}
</script>
