<template>
  <div style="flex-direction: column;display: flex;justify-content: space-between;">
    <Nav />
    <Tablefull :posts="posts"/>
     </div>
</template>

<script>
import Nav from './components/Nav';
//import Teste from './components/Teste';
import Tablefull from './components/Tablefull';
import api from './api'

export default {
  components: {
    Nav,
    Tablefull,
  //   Teste
  },
    data() {
    return {
      section: "home",
      posts: [],
    }
  },
   methods: {   
   async fetchNews() {
      try {
       
        const response = await api.get("entregas")
        const results = response.data.posts
        this.posts = results
      } catch (err) {
        if (err.response) {
          // client received an error response (5xx, 4xx)
          console.log("Server Error:", err)
        } else if (err.request) {
          // client never received a response, or request never left
          console.log("Network Error:", err)
        } else {
          console.log("Client Error:", err)
        }
      }
    },
    
  
  mounted() {
    this.fetchNews()
    
  },
},
beforeMount(){
    this.fetchNews();
 },
 created () {
	setInterval(() => {
		this.fetchNews();
	}, 1000)
}
}
 
</script>
<style>



.nav {
    position: fixed;
    height: 90px;
    top: 0px;
    left: 0px;
    right: 0px;
    margin-top: 0px;
}
</style>