<template>
    <transition-group name='fade' tag='div'>
      <div v-for="i in [currentIndex]" :key='i'>
        <img :src="currentImg" class="imgFold"/>
      </div>
    </transition-group>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Slider',
  data() {
    return {
      images: [],
      timer: null,
      currentIndex: 0,
      timer2:null,
    }
  }, 
  
    mounted: function() {
      this.PegarDados();
      this.startSlide();
    },
    methods: {
      startSlide: function() {
        this.timer = setInterval(this.next, 48000);
      },
       async PegarDados(){
        const resposta = await axios.get('http://entregas.techmund.com.br/admin/loadinteger.php');
        if (resposta.status == 200){
          const data = resposta.data.files;
          var result = [];  
          for(var i in data)
          result.push([data[i]]);
          JSON.stringify(result)
          this.images = result
          }
        else{
          console.log("error")}
      },  
        next: function() {
        this.currentIndex += 1
        },
        prev: function() {
        this.currentIndex -= 1
      },    
    },
    created () {
      setInterval(() => {
        this.PegarDados();
      }, 5000)
    },

    computed: {
      currentImg: function() {
        return this.images[Math.abs(this.currentIndex) % this.images.length];
      }
    }
  
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: 18.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width:100%;
  opacity: 1;  
}

.fade-enter,
.fade-leave-to {
  visibility: hidden;
  width:100%;
  opacity: 0;
  height:86vh;
}

img {
  
  
 
}
.imgFold{
 height:86vh;
 width:100%;
 object-fit:fill;

}


</style>
